import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { PageAPIData } from '../../../types/types';

export const PAGE_DATA_SERVERLESS_URL = '/_serverless/bookings-form-page-data';

export type SetPageData = {
  pageData: PageAPIData;
  flowAPI: ControllerFlowAPI;
};

export type SetPageDataResponse =
  | {
      key: string;
    }
  | undefined;

export type GetPageDataResponse = {
  pageData?: PageAPIData;
  key?: string;
};
