import { FormApiContext } from '../utils';
import { getBPDIDFromQuery } from './utils';
import { GetPageDataResponse, PAGE_DATA_SERVERLESS_URL } from './types';
import { PageAPIData } from '../../../types/types';

export const getPageData = async ({
  httpClient,
  wixSdkAdapter,
}: FormApiContext): Promise<GetPageDataResponse | undefined> => {
  const BPDID = getBPDIDFromQuery(wixSdkAdapter.getUrlQueryParams()) || '';
  let response: GetPageDataResponse | undefined;

  if (BPDID) {
    response = (
      await httpClient.get<{ key: string; pageData: PageAPIData }>(
        `${PAGE_DATA_SERVERLESS_URL}/${BPDID}`,
      )
    )?.data;
  }

  return response;
};
