import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import { FormErrorsType, GenericErrorType } from '../../../../types/errors';
import { CreateActionParams } from '../actions';
import { bookingsLoginClick } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  getDefaultPaymentOptionId,
  getPaymentOptions,
  getPaymentTypeFromOption,
} from '../../../../utils/payment';
import { FormStatus } from '../../../../types/form-state';
import { ServiceType } from '@wix/bookings-uou-types';
import { getServiceSlotIdentifier, mapToArray } from '../../../../utils';
import { SlotService } from '../../../../utils/state/types';
import { DateTimeFormatter } from '@wix/bookings-date-time';
import { ExperimentsConsts } from '../../../../consts/experiments';

export type OnLogin = (user?: IUser) => Promise<void>;

export function createOnLoginAction({
  getControllerState,
  context,
  internalActions,
  flowApi,
}: CreateActionParams): OnLogin {
  return async (user) => {
    const [state, setState] = getControllerState();
    const {
      isPricingPlanInstalled,
      isBookingsOnEcom,
      serviceData,
      formSelectedSlot,
      benefitsWithPlanInfo,
      businessInfo,
      formInputs,
    } = state;
    const { formApi, settings, biLogger, reportError, t } = context;
    const { errorHandlers } = internalActions;

    biLogger?.report(bookingsLoginClick({}));

    const onError = (error: FormErrorsType) => errorHandlers.addError(error);

    if (user) {
      if (flowApi.experiments.enabled(ExperimentsConsts.ReinitializeOnLogin)) {
        setState({
          status: FormStatus.REINITIALIZING,
          overrideDefaultFieldsValues: true,
        });
      } else {
        try {
          const [memberDetails, pricingPlanDetails, memberships] =
            await Promise.all([
              formApi.getMemberDetails({
                id: user.id,
                onError,
              }),
              !isBookingsOnEcom &&
              !(
                mapToArray<SlotService>(serviceData.slotServices)[0].service
                  .type === ServiceType.COURSE
              )
                ? formApi.getPricingPlanDetails({
                    formSelectedSlot,
                    onError,
                  })
                : undefined,
              isBookingsOnEcom
                ? formApi.listMemberships({
                    formSelectedSlot,
                    onError,
                  })
                : undefined,
            ]);

          const dateAndTimeFormatter = new DateTimeFormatter(
            businessInfo.dateRegionalSettingsLocale!,
          );

          const updatedSlotServices = mapToArray<SlotService>(
            serviceData.slotServices,
          ).reduce((acc, slotService) => {
            const currentMemberShips =
              memberships?.[getServiceSlotIdentifier(slotService.nestedSlot)];
            if (!currentMemberShips) {
              return acc;
            } else {
              const paymentOptions = getPaymentOptions({
                servicePayment: slotService.service?.payment,
                benefitsWithPlanInfo,
                pricingPlanDetails,
                memberships: currentMemberShips,
                isPricingPlanInstalled,
                dateAndTimeFormatter,
                numberOfParticipants: formInputs.numberOfParticipants,
                dateRegionalSettingsLocale:
                  businessInfo.dateRegionalSettingsLocale!,
                isDynamicPreferenceType:
                  slotService.dynamicPriceInfo?.isDynamicPreferenceType,
                t,
                settings,
              });

              const selectedPaymentOptionId = getDefaultPaymentOptionId({
                settings,
                servicePayment: slotService.service.payment,
                pricingPlanDetails,
                memberships: currentMemberShips,
                isPricingPlanInstalled,
              });

              const selectedPaymentOption = paymentOptions.find(
                (paymentOption) => paymentOption.id === selectedPaymentOptionId,
              );

              acc[getServiceSlotIdentifier(slotService.nestedSlot)] = {
                ...slotService,
                ...(selectedPaymentOption
                  ? {
                      selectedPaymentOption,
                      selectedPaymentType: getPaymentTypeFromOption(
                        selectedPaymentOption,
                      ),
                    }
                  : {}),
                memberships: currentMemberShips,
              };

              return acc;
            }
          }, {} as { [key: string]: SlotService });

          setState({
            memberDetails,
            ...(pricingPlanDetails ? { pricingPlanDetails } : {}),
            status: FormStatus.IDLE,
            overrideDefaultFieldsValues: true,
            serviceData: {
              ...serviceData,
              slotServices: updatedSlotServices,
            },
          });
        } catch (error) {
          errorHandlers.addError(error as any);
          setState({ status: FormStatus.IDLE });
          reportError(error as any);
        }
      }
    } else {
      errorHandlers.addError(GenericErrorType.GENERIC_MEMBER_DETAILS_ERROR);
      reportError(GenericErrorType.GENERIC_MEMBER_DETAILS_ERROR);
    }
  };
}
