import { CreateActionParams } from '../actions';
import { FormStatus } from '../../../../types/form-state';
import { SelectedAdditionalTimeSlot } from '../../../../tpa-plugins/additional-time-slots/types';

export type AddAdditionalSlot = (
  selectedAdditionalTimeSlot: SelectedAdditionalTimeSlot,
) => Promise<void>;

export function createAddAdditionalSlotAction({
  getControllerState,
  flowApi,
  context: { formApi },
}: CreateActionParams): AddAdditionalSlot {
  return async (selectedSlots) => {
    const [, setState] = getControllerState();
    const { pageData } = (await formApi.getPageData()) || {};

    if (!pageData) {
      throw new Error('page data are not found');
    }

    if (!pageData.additionalSlots) {
      pageData.additionalSlots = [];
    }

    for (const selectedSlot of selectedSlots) {
      pageData.additionalSlots.push(selectedSlot.slotAvailability);

      if (selectedSlot.dynamicPrice) {
        if (!pageData.dynamicPricePreSelection) {
          pageData.dynamicPricePreSelection = [];
        }

        pageData.dynamicPricePreSelection.push({
          serviceId: selectedSlot?.slotAvailability?.slot?.serviceId!,
          startDate: selectedSlot?.slotAvailability?.slot?.startDate!,
          dynamicPriceInfo: selectedSlot.dynamicPrice,
        });
      }
    }

    await formApi.setPageData({
      pageData,
      flowAPI: flowApi,
    });

    setState({
      status: FormStatus.REINITIALIZING,
    });
  };
}
