import { FormApiContext } from '../utils';
import {
  SetPageData,
  SetPageDataResponse,
  PAGE_DATA_SERVERLESS_URL,
} from './types';
import { getBPDIDFromQuery } from './utils';
import { QueryParamName } from '../../PageAPIAdapter/URLAdapter/URLAdapter';

export const setPageData = async ({
  pageData,
  flowAPI,
  httpClient,
  wixSdkAdapter,
}: SetPageData & FormApiContext): Promise<SetPageDataResponse> => {
  const response = (
    await httpClient.post<{ key: string }>(PAGE_DATA_SERVERLESS_URL, {
      pageData,
      key: getBPDIDFromQuery(wixSdkAdapter.getUrlQueryParams()),
    })
  )?.data;

  flowAPI.controllerConfig.wixCodeApi.location.queryParams.add({
    [QueryParamName.BPDID]: response.key,
  });

  return response;
};
