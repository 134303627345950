import { createSlotVeloAPIFactory } from '@wix/widget-plugins-ooi/velo';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { InitialTpaPlugins } from '../../types';
import {
  AdditionalTimeSlot$w,
  SelectedAdditionalTimeSlot,
  SLOT_NAME,
} from '../types';
import { FormSelectedSlot } from '../../../types/formSelectedSlots';

export class AdditionalTimeSlots {
  private additionalTimeSlot$w: AdditionalTimeSlot$w & {
    isMethodSupported: (methodName: string) => boolean;
  };
  private context: InitialTpaPlugins;

  constructor(context: InitialTpaPlugins) {
    const slotAPIFactory = createSlotVeloAPIFactory(context.controllerConfig);

    this.context = context;
    this.additionalTimeSlot$w = slotAPIFactory.getSlotAPI(SLOT_NAME) as any;
  }

  async runListeners() {
    if (this.additionalTimeSlot$w.isMethodSupported('onAddTimeSlot')) {
      this.additionalTimeSlot$w.onAddTimeSlot(this.onAddTimeSlot);
    }
  }

  async onAddTimeSlot(additionalTimeSlotOutput: SelectedAdditionalTimeSlot) {
    await this.context.actions.addAdditionalSlot(additionalTimeSlotOutput);
  }

  static setAdditionalTimeSlotInput(
    formSelectedSlot: FormSelectedSlot,
    controllerConfig: IWidgetControllerConfig,
  ) {
    const slotAPIFactory = createSlotVeloAPIFactory(controllerConfig);
    const slotAPI = slotAPIFactory.getSlotAPI(SLOT_NAME) as any;

    slotAPI.AdditionalTimeSlotInput = formSelectedSlot;
  }
}
